// Typography overrides.

export const fonts = {
	body: "Arial, sans-serif",
	heading: "Arial, sans-serif",
};

export const fontSizes = {
	xs: "0.75em",
	sm: "0.875em",
	md: "1em",
	lg: "1.125em",
	xl: "1.25em",
	"2xl": "1.5em",
	"3xl": "1.875em",
	"4xl": "2.25em",
	"5xl": "3em",
	"6xl": "3.75em",
	"7xl": "4.5em",
	"8xl": "6em",
	"9xl": "8em",
};

export const lineHeights = {
	3: ".75em",
	4: "1em",
	5: "1.25em",
	6: "1.5em",
	7: "1.75em",
	8: "2em",
	9: "2.25em",
	10: "2.5em",
};

export const textVariants = {
	"gray-shadow": {
		textShadow: ".085106383em .085106383em 0 #333",
	},
	"gray-blurred-shadow": {
		textShadow: "0.1025641em 0.1025641em 0.05128205em #333",
	},
	"gray-outline": {
		WebkitTextStroke: ".0394736842em #333",
		color: "transparent",
	},
	"gray-outline-with-shadow": {
		textShadow: ".078947368em .078947368em 0 rgba(51,51,51,.4)",
		WebkitTextStroke: ".0394736842em #333",
		color: "transparent",
	},
	"white-shadow": {
		textShadow: ".085106383em .085106383em 0 #fff",
	},
	"white-shadow-alt": {
		textShadow: "-0.085106383em 0.085106383em 0 #fff",
	},
	"white-outline": {
		WebkitTextStroke: ".0394736842em #FFF",
		color: "transparent",
	},
	"white-outline-with-shadow": {
		textShadow: ".078947368em .078947368em 0 #333",
		WebkitTextStroke: ".0394736842em #FFF",
		color: "transparent",
	},
};
