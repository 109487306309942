import { textVariants } from "../foundations/typography";

// Heading component overrides.
const Heading = {
	baseStyle: {
		// textTransform: "uppercase",
		// fontWeight: 900,
	},
	variants: textVariants,
};

export default Heading;
