// Button component overrides.
const Button = {
	baseStyle: {
		textTransform: "uppercase",
	},
	defaultProps: {
		colorScheme: "blue",
	},
};

export default Button;
