// Link component overrides.
const Link = {
	baseStyle: {
		_hover: {
			textDecoration: "none",
		},
	},
};

export default Link;
