const styles = {
	global: {
		body: {
			color: "white",
			backgroundColor: "gray.900",
		},
		h1: {
			fontSize: "3xl",
			marginBottom: ".5em",
			color: "white",
		},
		h2: {
			fontSize: "2xl",
			marginBottom: ".5em",
			color: "white",
		},
		h3: {
			fontSize: "xl",
			marginBottom: ".5em",
			color: "white",
		},
		h4: {
			fontSize: "lg",
			marginBottom: ".5em",
			color: "white",
		},
		h5: {
			fontSize: "md",
			marginBottom: ".5em",
			color: "white",
		},
		h6: {
			fontSize: "sm",
			marginBottom: ".5em",
			color: "white",
		},
		p: {
			marginBottom: "1.2em",
			color: "white",
		},
		".text-right": {
			textAlign: "right",
		},
		".text-center": {
			textAlign: "center",
		},
		".text-left": {
			textAlign: "left",
		},
		".text-uppercase": {
			textTransform: "uppercase",
		},
		".text-lowercase": {
			textTransform: "lowercase",
		},
		".text-capitalize": {
			textTransform: "capitalize",
		},
		svg: {
			maxW: "100%",
		},
	},
};

export default styles;
