import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

// Global style overrides.
import styles from "./styles";

// Color overrides.
// import colors from "./colors";

// Typography overrides.
import {
	fonts,
	fontSizes,
	lineHeights,
	textVariants,
} from "./foundations/typography";

// Component overrides.
import { Button, Heading, Link } from "./components";

// Breakpoint overrides.
const breakpoints = createBreakpoints({
	xs: "380px",
	sm: "420px",
	md: "767px",
	lg: "1080px",
	xl: "1440px",
});

const overrides = {
	styles,
	breakpoints,
	config: {
		// Branding, bro
		cssVarPrefix: "sk",
	},
	components: {
		Button,
		Heading,
		Link,
		Text: {
			variants: textVariants,
		},
	},
	// colors,
	fonts,
	fontSizes,
	lineHeights,
	sizes: {
		container: {
			xs: "380px",
			sm: "420px",
			md: "767px",
			lg: "1080px",
			xl: "1440px",
		},
	},
};

export default extendTheme(overrides);
